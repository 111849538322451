:root {
  --app-color-light-text: #ffffff;
  --app-color-dark-text: #000000;
  --app-color-accent: #f0b2bf;
  --app-color-light-background: #ffffff;
  --app-color-gray-background: #f5f5f5;

  --ion-color-primary: #1b191d;
  --ion-color-primary-rgb: 27, 25, 29;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #18161a;
  --ion-color-primary-tint: #323034;

  --ion-color-secondary: #f0b2bf;
  --ion-color-secondary-rgb: 240, 178, 191;
  --ion-color-secondary-contrast: var(--ion-color-primary);
  --ion-color-secondary-contrast-rgb: 0, 0, 0;
  --ion-color-secondary-shade: #d39da8;
  --ion-color-secondary-tint: #f5dce1;

  --ion-color-tertiary: #ffffff;
  --ion-color-tertiary-rgb: 255, 255, 255;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0, 0, 0;
  --ion-color-tertiary-shade: #e0e0e0;
  --ion-color-tertiary-tint: #ffffff;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;

  /* Color of app */
  --ion-background-color: var(--ion-color-primary);
  --ion-text-color: var(--ion-color-tertiary);
  --ion-item-background: transparent;

  --item-title-color: #232225;
}

body {
  color: var(--ion-color-tertiary);
}

.app-action-sheet .action-sheet-group,
.app-action-sheet .action-sheet-group-cancel {
  --button-background-selected: white;
}

.pepalexa-card {
  --pepalexa-card--color--background: #1c1c1e;
}

.pepalexa-avatar--bordered {
  --pepalexa-avatar--border-color: var(--ion-color-secondary);
  --pepalexa-avatar--border-width: 1px;
}

.pepalexa-avatar--list {
  --pepalexa-avatar--margin: 0 10px 0 0;
  --pepalexa-avatar--diameter: 30px;
}

.pepalexa-avatar--medium {
  --pepalexa-avatar--margin: 0 auto 10px;
  --pepalexa-avatar--diameter: 45px;
}

.pepalexa-avatar--large {
  --pepalexa-avatar--margin: 0 auto 10px;
  --pepalexa-avatar--diameter: 90px;
}

.pepalexa-flag {
  --pepalexa-flag--color--background: var(--ion-color-secondary);
  --pepalexa-flag--color--text: var(--ion-color-tertiary);
}

.pepalexa-flag--grey {
  --pepalexa-flag--color--background: #e7e7e7;
  --pepalexa-flag--color--text: #000000;
  --pepalexa-flag--margin: 0 0 0 10px;
  padding: 10px !important;
  line-height: 30px !important;
  border-radius: 20px !important;
}

.pepalexa-flag--up {
  --pepalexa-flag--margin: 0 5px auto;
}

.pepalexa-flag--top-right-corner {
  --pepalexa-flag--position: absolute;
  --pepalexa-flag--top: 0;
  --pepalexa-flag--right: 15px;
}

.pepalexa-media-with-overlap {
  --pepalexa-media-with-overlap--border-color: var(--ion-color-secondary);
}

.pepalexa-media-with-overlap--small {
  --pepalexa-media-with-overlap--diameter: 40px;
  --pepalexa-media-with-overlap--diameter-overlap: 15px;
  --pepalexa-media-with-overlap--border-color-overlap: var(--ion-color-secondary);
  --pepalexa-media-with-overlap--border-color: var(--ion-color-secondary);
  --pepalexa-media-with-overlap--overlap-top: -2px;
  --pepalexa-media-with-overlap--overlap-right: -8px;
}

.pepalexa-media-with-overlap__icon-container {
  border: 1px solid var(--ion-color-secondary);
  border-radius: 50%;
  display: flex;
  height: 100%;
  width: 100%;
}

.pepalexa-media-with-overlap__icon {
  border: unset !important;
  border-radius: unset !important;
  height: 20px !important;
  margin: auto;
  object-fit: unset !important;
  width: 20px !important;
}

.pepalexa-media-with-overlap--selected {
  --pepalexa-media-with-overlap--border-color: var(--ion-color-secondary);
  --pepalexa-media-with-overlap--border-color-overlap: var(--ion-color-dark);
  --pepalexa-media-with-overlap--background-overlap: var(--ion-color-secondary);
  --pepalexa-media-with-overlap--text-color: var(--ion-color-tertiary);
}

.pepalexa-heading {
  --pepalexa-heading--font-size: 24px;
  --pepalexa-heading--line-height: 32px;
  --pepalexa-heading--font-weight: bold;
}

.pepalexa-heading--highlighted {
  --pepalexa-heading--color--text: var(--ion-color-secondary);
  --pepalexa-heading--font-size: 34px;
  --pepalexa-heading--line-height: 40px;
  --pepalexa-heading--font-weight: normal;
}

.pepalexa-heading--color-secondary {
  --pepalexa-heading--color--text: var(--ion-color-secondary);
}

.pepalexa-heading--highlighted-white {
  --pepalexa-heading--font-size: 34px;
  --pepalexa-heading--line-height: 40px;
  --pepalexa-heading--color--text: #ffffff;
}

.pepalexa-tabs {
  --pepalexa-tabs--color--text: var(--ion-color-tertiary);
  --pepalexa-tabs--color--text--selected: var(--ion-color-secondary);
  --pepalexa-tabs--opacity: 1;
}

.pepalexa-media--sharp {
  --pepalexa-media--border-radius: 0;
}

.pepalexa-button {
  --pepalexa-button--background: transparent;
  --pepalexa-button--color--text: var(--ion-color-tertiary);
  --pepalexa-button--border--color: var(--ion-color-secondary);
}

.pepalexa-button--secondary {
  --pepalexa-button--background: var(--ion-color-secondary);
  --pepalexa-button--color--text: black;
}

.pepalexa-icon--centered {
  --pepalexa-icon--margin: 0 auto;
}

.pepalexa-icon--list-item {
  --pepalexa-icon--margin: 0 10px 0 0;
}

.pepalexa-icon--size-medium {
  --pepalexa-icon--height: 34px;
}

.pepalexa-content--opacity {
  --pepalexa-content--opacity: 0.6;
}

.pepalexa-content--text-right {
  --pepalexa-content--text-align: right;
}

.pepalexa-content--text-center {
  --pepalexa-content--text-align: center;
}

.pepalexa-container {
  --pepalexa-container--padding: 0px 20px;
}

.pepalexa-container--rounded-top {
  --pepalexa-container--border-radius: 17px 17px 0 0;
}

.pepalexa-container--no-padding {
  padding: 0 !important;
}

.pepalexa-container--rounded {
  --pepalexa-container--border-radius: 17px;
}

ion-range {
  --knob-border-radius: 50%;
  --knob-background: var(--ion-color-primary-contrast);
  --knob-box-shadow: 0px 0px 7px var(--ion-color-secondary);
  --knob-size: 30px;
  --bar-height: 2px;
  --bar-background: var(--ion-color-secondary);
  --bar-background-active: var(--ion-color-secondary, --ion-color-secondary-contrast);
  --bar-border-radius: 0;
  --height: 42px;
  --pin-background: var(--ion-color-medium);
  --pin-color: var(--ion-color-secondary, --ion-color-secondary-contrast);
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 14px;
  padding-right: 14px;
  font-size: 18px;
}

ion-range {
  padding: 12px 16px;
  --knob-background: #f0b3bf;
  --knob-box-shadow: 0px 2px 4px var(--ion-color-secondary);
  --knob-size: 36px;
}

ion-range::part(pin) {
  color: #000000;
  font-size: 14px;
  z-index: 10;
  top: 21px;
  transform: none !important;
}

ion-range::part(knob) {
  border: 1px solid #1b191d;
}

ion-list {
  padding: 0;
}

ion-list {
  &.padding-top {
    padding-top: 30px;
  }
}

ion-col {
  position: relative;
  padding-inline-end: 0;
  padding-inline-start: 0;
}

ion-grid {
  padding-inline-end: 0;
  padding-inline-start: 0;
}

ion-row {
  padding-top: 0;
  padding-bottom: 0;
}

ion-popover.select-popover {
  --max-width: 220px;
  --background: white;
  ion-item {
    --color: black;
  }
}

ion-loading.ios {
  color: #000;
}

ion-modal.action-modal {
  --background: transparent;
  --box-shadow: none;
}

ion-action-sheet.md {
  --button-color: white;
}

.wrapper {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.wrapper__column {
  width: 100%;
  box-sizing: border-box;
}

.wrapper__column--flex {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.wrapper__column--space {
  padding: 20px;
}

.wrapper__column--centered {
  margin: auto;
}

.padding {
  padding: 10px 20px;
}

.ion-item--informative {
  --ion-color-step-600: rgba(235, 235, 245, 0.6);
  padding-inline-start: 0;
  --padding-start: 0;
}

.ion-item--informative ion-label p {
  font-size: 13px;
}

.ion-item--padding {
  --padding-top: 10px;
  --padding-bottom: 10px;
}

.ion-item--no-padding {
  --padding-start: 0;
  --inner-padding-end: 0;
}

.ion-item--border-light {
  box-shadow: 0 0.5px 0 rgba(255, 255, 255, 0.3);
}

.ion-item--border-light--double {
  box-shadow: 0 0.5px 0 rgba(255, 255, 255, 0.3), 0 -0.5px 0 rgb(255 255 255 / 30%);
}

.ion-item--border-transparent {
  box-shadow: none;
}

.ion-item--color-highlighted {
  --ion-item-color: var(--ion-color-secondary);
}

.ion-item--dark-text {
  --ion-item-color: black;
}

.ion-item--highlighted-subtitle {
  --ion-color-step-600: var(--ion-color-secondary);
}

.color-secondary {
  color: var(--ion-color-secondary);
}

.margin-bottom-medium {
  margin-bottom: 40px;
}

.app-mock-container {
  max-width: 390px;
  height: 844px;
  margin: auto;
  border: 5px solid red;
  border-radius: 10px;
}

.message-name {
  font-size: 12px;
}

.practices-list {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.practices-list ion-item:first-of-type {
  margin: 0 0 2px 0;
}

.practices-list ion-item {
  margin: 2px 0;
}

.practices-list ion-item:last-of-type {
  margin: 2px 0 0 0;
}

.select-alert {
  --background: #ffffff !important;
}

.pepalexa-textarea__textarea {
  --background: rgba(255, 255, 255, 0.1) !important;
  --placeholder-opacity: 1 !important;
  border-radius: 10px;
}

.button-container {
  text-align: center;
}

.pink-select {
  color: #f0b3bf !important;
}

.pink-border {
  border-top: 0.5px solid #f0b3bf;
  border-bottom: 0.5px solid #f0b3bf;
  box-shadow: none;
}

.list-item-small-avatar__label {
  font-size: 17px !important;
  font-weight: 600;
}

.pepalexa-media--round img {
  width: 120px !important;
  height: 120px !important;
  border-radius: 50% !important;
  margin: auto !important;
}

.pepalexa-heading--center {
  text-align: center;
}

.subtitle {
  text-align: center;
}

.pepalexa-title-and-list {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.pepalexa-title-and-list__title {
  margin-right: 10px;
}

.pepalexa-title-and-list__title p {
  margin: 0;
}

.pepalexa-title-and-list__list p {
  color: #f0b3bf;
  font-size: 16px;
  margin: 0 0 7px 0;
}

.subscription-item__type {
  color: #ffffff !important;
  font-size: 14px !important;
  font-weight: 600;
}

.subscription-item__type span {
  font-weight: normal;
  margin-left: 5px;
}

.subscription-item__period {
  color: #ffffff !important;
  font-size: 12px !important;
  font-weight: 600;
}

.subscription-item__period span {
  font-weight: normal;
  margin-left: 5px;
}

.subscription-item {
  border-bottom: rgba(255, 255, 255, 0.22) 1px solid;
  border-top: rgba(255, 255, 255, 0.22) 1px solid;
  margin: 10px 0;
}

.subscription-item--pink {
  border-bottom: 0 !important;
  border-top: 0 !important;
  box-shadow: none !important;
}

.subscription-item--pink .subscription-item__type {
  color: #f0b3bf !important;
  font-size: 12px !important;
  text-align: center;
}

.subscription-item--pink .subscription-item__period {
  color: #f0b3bf !important;
  text-align: center;
}

.pepalexa-timestamp--bottom-right-corner {
  position: absolute;
  bottom: 0;
  right: 5px;
}

.pepalexa-timestamp--grey {
  color: #a3a3a3 !important;
}

.notification-item-no-avatar__title {
  color: #000000 !important;
  font-size: 17px !important;
  font-weight: 600 !important;
  line-height: 50px !important;
}

.pepalexa-item-no-padding::part(native) {
  --padding-start: 0;
}

.pepalexa-item-no-padding {
  --inner-padding-end: 0 !important;
}

.pepalexa-toggle {
  --background-checked: #f0b3bf;
}

.pepalexa-white-item {
  --background: #ffffff !important;
}

.pepalexa-white-item ion-label {
  color: #000000 !important;
}

.pepalexa-white-item ion-select {
  color: #000000 !important;
}

.pepalexa-white-item ion-select::part(icon) {
  color: #000000 !important;
}

.pepalexa-item-border-bottom {
  box-shadow: 0;
  border-bottom: 1px solid rgba(240, 179, 191, 0.7);
}

.pepalexa-item-border {
  box-shadow: 0;
  border-bottom: 1px solid rgba(240, 179, 191, 0.7);
  border-top: 1px solid rgba(240, 179, 191, 0.7);
}

.pepalexa-small-title--margin-top {
  margin-top: 10px !important;
}

.notes-item {
  --padding-top: 20px;
  --padding-bottom: 20px;
}

.notes-item--title {
  color: #1b191d !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  margin-bottom: 10px !important;
}

.notes-item--subtitle {
  color: #1b191d !important;
  font-size: 16px !important;
}

.select-interface-option .alert-radio-icon {
  display: none !important;
}

.select-interface-option .alert-radio-label {
  -webkit-padding-start: 13px !important;
  padding-inline-start: 13px !important;
}

.alert-radio-group {
  border-top: 0 !important;
  border-bottom: 0 !important;
  padding: 0 10px !important;
}

.notification-item-small-avatar__title {
  color: #000000 !important;
  font-size: 17px !important;
  font-weight: 600 !important;
  line-height: 50px !important;
  margin-left: 10px !important;
}

.pepalexa-media--with-border img {
  border: 2px solid #f0b3bf;
}

.pepalexa-flag--top-right-corner-with-icon {
  --pepalexa-flag--position: absolute;
  --pepalexa-flag--top: 0;
  --pepalexa-flag--right: 40px;
}

.pepalexa-icon--top-right-corner {
  position: absolute;
  top: 0;
  right: 10px;
}

.app-tab-bar {
  --background: #222428c9;
  --color: var(--ion-color-secondary);
}

.app-tab-bar ion-icon {
  border: 1px solid var(--ion-color-secondary);
  border-radius: 50%;
  padding: 8px;
  font-size: 20px;
}

.app-tab-bar .tab-selected ion-icon {
  background-color: var(--ion-color-secondary);
}

.app-tab-bar ion-tab-button:hover {
  ion-icon {
    border: 1px solid rgba(240, 179, 191, 0.6);
    color: rgba(240, 179, 191, 0.6);
  }
}

.app-tab-bar .tab-selected:hover {
  ion-icon {
    color: #222428c9;
  }
}

.pepalexa-item--box-shadow-none {
  box-shadow: none !important;
}

.dashboard-heading-container {
  padding: 0 30px;
  margin: 30px 0 14px;
}

.dashboard-heading-container .pepalexa-media-with-overlap {
  margin-bottom: 16px;
}

.dashboard-heading-container .pepalexa-content--margin-bottom-medium {
  margin-bottom: 16px;
}

.pink-text {
  color: var(--ion-color-secondary);
}

.notification-list-item {
  --padding-start: 0;
  --border-color: #f0b3bf;
  --inner-padding-bottom: 16px;
  box-shadow: none;
}

.notification-list-item ion-label {
  position: relative;
  margin: 0;
}

.notification-list-item h2 {
  font-size: 17px;
  font-weight: 500;
}

.notification-list-item .item-subtitle {
  color: #f0b3bf;
}

.alert-wrapper {
  background: #ffffff !important;
}

.alert-title,
.alert-message {
  color: #1b191d !important;
}

.notification-list-item {
  h2 {
    color: var(--item-title-color);
    font-weight: 600;
  }
}

.pepalexa-content--menu {
  border-bottom: 1px solid rgba(255, 255, 255, 0.36);

  .title {
    font-size: 24px;
    font-weight: 700;
    margin: 92px 0 39px;
    text-align: center;
  }
}

.menu-item {
  --border-color: rgba(240, 179, 191, 0.3);
  --detail-icon-color: #ffffff;
  --detail-icon-opacity: 1;
  --inner-padding-end: 0;
  --padding-start: 0;
  --min-height: 78px;

  ion-icon {
    font-size: 32px !important;
  }
}

.pepalexa-calendar__day {
  background: transparent;
}

.pepalexa-calendar__button {
  background: transparent;
  color: #ffffff;
  font-size: 20px;
}

.pepalexa-calendar__day--is-selected::before {
  top: -4px;
}

ion-toggle {
  --background-checked: #f0b2bf;
  --handle-background-checked: #ffffff;
}

.pepalexa-media {
  img,
  video {
    max-height: 100%;
    max-width: 500px;
    height: unset;
    margin: auto;
  }
}

.searchbar-input-container {
  color: #ffffff;
}

.pepalexa-message-box--recieving::before {
  left: -7px;
  width: 20px;
  background-color: var(--_color-receiving);
  border-bottom-right-radius: 16px 14px;
}

.pepalexa-message-box--recieving::after {
  left: -10px;
  width: 10px;
  border-bottom-right-radius: 10px;
}

textarea {
  background: #ffffff;
  border-radius: 5px;
  color: #000000;
}

.message_columns {
  display: flex;
  align-items: center;

  ion-item {
    width: 100%;
  }
}

.bottom-spacer-message {
  height: 61px !important;
}

.pepalexa-media-with-overlap__overlap img + img {
  top: 30px;
  right: -15px;
}

.app-header {
  ion-toolbar {
    --background: #222428;
    ion-buttons {
      --opacity: 1;
    }
    ion-button,
    ion-menu-button,
    ion-back-button {
      --color: white;
    }
  }
}

.app-header.app-hiding-header {
  .header-background {
    visibility: hidden;
  }

  position: absolute;
  top: 0;
  left: 0;

  ion-toolbar {
    --opacity: 0;
  }

  ion-title {
    opacity: 0;
    visibility: hidden;
    transition: opacity ease-in-out 200ms;
  }
}

.app-header.app-hiding-header.app-header--invisible::after {
  background-image: none;
}

.app-header.app-hiding-header.app-header--visible ion-title {
  opacity: 1;
  visibility: visible;
}

.app-header.app-hiding-header.app-header--visible ion-toolbar {
  --opacity: 0.8;
}

.header-md.app-header.app-hiding-header.app-header--visible ion-toolbar {
  --opacity: 1;
}

.app-header.app-hiding-header.app-header--visible .header-background {
  visibility: visible;
}

.app-hiding-header-spacer {
  height: 91px;
  height: calc(50px + env(safe-area-inset-top, 0px));
}

.app-translucent-tab-bar-spacer {
  height: env(safe-area-inset-bottom, 0px);
}

.tmp-spacer {
  height: env(safe-area-inset-top, 0px);
}

.app-tab-bar {
  height: 60px;
  --background: #222428c9;
  --color: #f0b3bf;

  ion-icon {
    border: 1px solid #f0b3bf;
    border-radius: 50%;
    padding: 8px;
    font-size: 20px;
  }

  .tab-selected ion-icon {
    background-color: #f0b3bf;
  }
}

.minimal-list-item {
  border-bottom: 0.5px solid #5f5f5f;
}

.profile-list {
  .minimal-list-item:last-of-type {
    border-bottom: none;
  }
}

.pepalexa-horizontal-card,
.pepalexa-card {
  text-decoration: none;
}

/* Hack to not write cointent under translucent tab bar. */
html[mode="ios"] .bottom-spacer {
  height: 91px;
}

html[mode="ios"] .app-tab-bar {
  --background: #222428c9;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
}

/* Hack to not get wrong position on youtube embeds. */
.pepalexa-media {
  iframe {
    object-fit: unset;
  }
}

.gallery-column {
  padding-inline-start: 1px;
  padding-inline-end: 1px;
}

a > .pepalexa-card {
  color: white;
  text-decoration: none;
}

.ion-item--informative {
  p {
    color: rgba(255, 255, 255, 0.5);
  }
}

.pepalexa-content {
  &--small-margin-top {
    margin-top: 15px;
  }

  &--small-margin-bottom {
    margin-bottom: 15px;
  }
}

.pepalexa-text--wrapping {
  white-space: pre-wrap;
}

.pepalexa-media-with-overlap {
  &__icon {
    color: #f0b3bf;
  }

  &--selected {
    .pepalexa-media-with-overlap__icon {
      color: #000000 !important;
    }
  }
}

.menu-list ion-icon {
  color: var(--ion-color-secondary);
  font-size: 24px;
}

.form {
  border-radius: 10px;
  padding: 0;
  overflow: hidden;
  ion-item {
    --background: rgb(245, 245, 245);
    --color: #000;
    --border-color: rgba(0, 0, 0, 0.12);
    --highlight-height: 0px;
  }
}

.light {
  ion-item {
    --background: transparent;
    --color: #000;
    --border-color: rgba(0, 0, 0, 0.12);
    box-shadow: unset;
  }
}
.context-menu {
  padding: 0;
  ion-icon {
    color: #fff;
  }
}

.checkbox-container {
  display: flex;
}

.checkbox-item {
  --padding-start: 0;
  color: #ffffff;
  box-shadow: none;

  &.item-checkbox-checked {
    color: #f0b3bf;
  }

  ion-checkbox {
    --border-radius: 3px;
    --background: rgba(0, 0, 0, 0);
    --background-checked: rgba(0, 0, 0, 0);
    --border-color: #ffffff;
    --border-color-checked: #f0b3bf;
    --checkmark-color: #f0b3bf;
    --size: 18px;
  }

  ion-label {
    margin-left: 7px;
  }
}

.pepalexa-range {
  ion-range {
    --knob-size: 36px !important;
    --pin-color: #ffffff;
    padding: 18px;
  }
}

.pepalexa-icon-block__icon {
  color: #f0b3bf;
}

.list-with-margin {
  margin: 12px 0;
}

.app-message-toolbar {
  ion-back-button {
    color: #ffffff;
  }

  .pepalexa-content {
    display: grid;
    margin-right: 22px;

    span {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.07px;
    }
  }
}

.pepalexa-calendar__day {
  &--has-info::after {
    bottom: 5px;
  }

  &--is-selected::before {
    height: 38px;
    width: 38px;
  }
}

.droppable-clone,
.app-block-selector {
  ion-item.level-0 {
    --background: var(--app-color-accent);
    --color: var(--app-color-dark-text);
  }
  ion-item.level-1 {
    --background: var(--app-color-gray-background);
    --color: var(--app-color-dark-text);
    --padding-start: 30px;
  }
}

.connector-entity {
  --background: var(--app-color-gray-background);
  --color: var(--app-color-dark-text);
}

.image-preview-container {
  height: 300px;
  width: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center center;
  }
}

.pepalexa-container--padding-bottom {
  --pepalexa-container--padding: 0px 20px 30px;
}

.pepalexa-button--black-text {
  --color: #000000;
  --border-color: #f0b3bf;
  --border-width: 1px;
  --border-style: solid;
  --background: transparent;
}

.pepalexa-media-with-overlap--pointer {
  cursor: pointer;
}

.three-icon-blocks__block {
  cursor: pointer;
}

.pepalexa-label--padding {
  margin: 16px 30px;
}

.profile-answer {
  padding: 10px 0;
  white-space: pre-wrap;
}

.edit-profile-input {
  padding: 10px 0;
  white-space: pre-wrap;
}

.pepalexa-message-box {
  @media screen and (min-width: 500px) {
    max-width: 48%;
  }
}

.connector-search-form {
  width: 100%;
}

.item-interactive {
  ion-label {
    white-space: unset !important;
  }
}

ion-item.notification-item {
  --border-color: #f0b3bf;
  ion-label {
    h2 {
      font-weight: 600;
    }
    p {
      color: #f0b3bf;
    }
  }
}

ion-toolbar.messages-toolbar {
  --min-height: 100px;
  ion-title div {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.07px;
    font-weight: 400;
  }
}

html.md ion-toolbar.messages-toolbar .ttt {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 48px;
  img {
    background-color: #fff;
  }
}

.media-preview-container {
  margin: 16px 0;
  width: 100%;
  img,
  iframe {
    margin: 0 auto;
    aspect-ratio: 16 / 9;
    max-width: 500px;
    width: 100%;
    display: block;
  }
}

.pepalexa-media-with-overlap__inner img {
  background-color: #fff;
}

.read-at {
  text-align: right;
  margin-top: 10px;
  font-size: 11px;
  letter-spacing: 0.07px;
  line-height: 13px;
}

.pepalexa-message-box-media__description {
  height: 37px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

html.ios .responsive-footer {
  margin-bottom: calc(env(safe-area-inset-bottom) + 60px);
}

html.ios .responsive-footer.keyboard-open {
  margin-bottom: 0px;
}

html.ios .pepalexa-content--safe-inset {
  margin-top: env(safe-area-inset-top, 30px);
}

.ck-editor__editable {
  height: 350px;
}

.pepalexa-message-box--in-transit {
  .spinner-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.pepalexa-message-box--error {
  background-color: #eb4034;
  &::before {
    background-color: #eb4034;
  }
}

ion-action-sheet.ios {
  --button-background-selected: #fff;
}

ion-alert.ios {
  --ion-text-color: #000;
}
.pepalexa-container.pepalexa-container--messages-container {
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}
